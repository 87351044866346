import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";
import SEO from "../components/SEO";

const BlogContainer = styled.div`
    padding: 10vh 2rem;
    width: 70%;
    margin: auto;

    a {
        text-decoration: underline;
    }
    
    h3 {
        font-size: 2rem;
        margin-bottom: 5px;
    }
    
    div:not(:last-child)::after {
        content: '';
        display: block;
        border-bottom: 1px dashed #393939;
        padding-top: 40px;
    }
    @media (max-width: 768px) {
        padding: 10vh 1rem;
        
    }
    @media (max-width: 480px) {
        width: 80%;
    }
`

function formatDate(date) { 
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }
    
    return new Date(date).toLocaleDateString("en-US", options)
}

export default function BlogPage({ data }) {
    const { nodes } = data.allSanityPost;
    nodes.sort((a, b) => -a.publishedAt.localeCompare(b.publishedAt));
    return (
        <>
             <SEO
                title="Blog + News"
                keywords={[`J. Marcelo Borromeo`, `Filipino writer`, `UEA`, `novel`, `short story`, `fiction`, `non-fiction`]}
                />
            <BlogContainer>
                {nodes.map(node => {
                     const preview = node._rawBody.find(body => body.children);
                    return (
                        <div key={node.id}>
                            <Link to={node.slug.current}>
                                <h3>{node.title}</h3>
                            </Link>
                            <span>{formatDate(node.publishedAt)}</span>
                            <PortableText value={preview} />
                            <Link to={node.slug.current}>
                                <span>Read More</span>
                            </Link>
                        </div>
                    )
                })}
            </BlogContainer>
        </>
    )
}

export const query = graphql`
    query Blog {
        allSanityPost {
            nodes {
              id
              title
              publishedAt
              _rawBody
              slug {
                current
              }
            }
          }
    }
`